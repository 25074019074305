// Author: T4professor

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
// import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';

@Component({
  selector: 'app-upload-renderer',
  template: `
      
      <div style="display: flex;">
      <ng-container *ngFor="let item of params.buttons; let i = index">
      <p-fileUpload mode="basic" *ngIf="item.upload && item.show"  name="myfile[]" maxFileSize="1000000" chooseLabel={{item.label}} chooseIcon={{item.icon}} (onSelect)="onBasicUpload($event, i)"></p-fileUpload>
      <button style="padding:5px"
            title={{item.label}} type="button" *ngIf="!item.upload && item.show" (click)="onBasicUpload($event, i)" class="btn {{item.class}} btn-sm">
        <i class="{{item.icon}}"></i>
      </button>
     </ng-container></div>
    `
})

export class ButtonUploadComponent implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onBasicUpload($event, idx) {
    if (this.params.buttons[idx].onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        index: this.params.rowIndex
        // ...something
      };
      this.params.buttons[idx].onClick(params);
    }
  }
}
