<div>
    <h4 class="text-center">Danh sách chứng minh thư</h4>
</div>
<p-galleria [value]="dataForm.metas" [(visible)]="displayCustom" [(activeIndex)]="activeIndex" [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '850px'}" [numVisible]="7"
    [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000"> 
    <ng-template pTemplate="item" let-item>
        <img [src]="item.metaUrl" style="width: 100%; display: block;"/>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
        <div class="p-grid p-nogutter p-justify-center">
            <img [src]="item.metaUrl" style="display: block;"/>
        </div>
    </ng-template>
</p-galleria>
<div *ngIf="dataForm.metas.length > 0" class="p-grid mt-3" style="max-width: 800px;display: flex; justify-content: center; margin-bottom: 24rem;">
    <div *ngFor="let image of dataForm.metas; let index = index;" class="col-md-4" key="index" style="border: 1px solid #ccc; margin-right: 5px">
        <div *ngIf="image.metaNote == 'Mặt trước'">
            <img  src="{{image.metaUrl ? image.metaUrl : '../../../assets/images/doccument/cmt-truoc.png'}}" [alt]="image.alt" style="cursor: pointer" class="w-100" (click)="imageClick(index)"/>
        </div>
        <div *ngIf="image.metaNote != 'Mặt trước'">
        <img  src="{{image.metaUrl ? image.metaUrl : '../../../assets/images/doccument/cmt-sau.png'}}" [alt]="image.alt" style="cursor: pointer" class="w-100" (click)="imageClick(index)"/>
        </div>
        <p class="mt-1 text-center" *ngIf="image.metaUrl">{{image.metaNote}}</p>
        <p class="mt-1 text-center" *ngIf="!image.metaUrl">
            <p-fileUpload name="myfile[]" *ngIf="isupload" mode="basic" [auto]="true" chooseIcon="pi pi-cloud-upload"  chooseLabel="{{image.metaNote}}" (onSelect)="onUploadOutput1($event, image.metaNote)"></p-fileUpload>
        </p>
    </div>
</div>