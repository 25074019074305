import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiCoreService } from './api-core/api-core.service';
const apiBase = `${environment.apiInvestBase}/api/v1/shome`;
const apiBondBaseUrl = environment.apiInvestBase;
const apiCoreBaseUrl = environment.apiCoreBase;
const apiKSFBase = environment.apiKSFBase;
const queryString = require('query-string');
@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private authService: AuthService) { }

  options = {
    headers: new HttpHeaders({
      'Authorization': this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
    })
  }

  /**
   * Template report grid page
   * @param {string} queryParams 
   * @param {string} url 
   */
  getTemplateReportPage(queryParams: string, url: string): Observable<any> {
    return this.http.get<any>(`${url}?${queryParams}`, this.options)
  }

  getRepOrderList(queryParams, path): Observable<any> {
    return this.http.get<any>(path + '?' + queryParams, this.options)
  }

  getSiptExchangePage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/exchange/GetExchangePage?` + queryParams, this.options)
  }

  getOrderWithdrawalPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetOrderWithdrawalPage?` + queryParams, this.options)
  }

  getRoomList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetRoomList?` + queryParams, this.options)
  }

  getSiptInterestPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/interest/GetOrderInterestPage?` + queryParams, this.options)
  }

  getOrderFinalPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/final/GetOrderFinalPage?` + queryParams, this.options)
  }

  getSiptWithdrawalPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/withdrawal/GetOrderWithdrawalPage?` + queryParams, this.options)
  }

  getWorkFlowPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/work/GetWorkflowPage?` + queryParams, this.options)
  }

  getCooperatorInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/cooperator/GetCooperatorInfo?` + queryParams, this.options)
  }

  getSiptDepoPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/depository/GetDepoPage?` + queryParams, this.options)
  }

  getSiptSchemePage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/scheme/GetSchemePage?` + queryParams, this.options)
  }

  getTransBankPayonPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/transaction/GetTransBankPayonPage?` + queryParams, this.options)
  }

  getParameterPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/work/GetParameterPage?` + queryParams, this.options)
  }

  getInvParameter(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/work/GetInvParameter?` + queryParams, this.options)
  }

  getTransBankPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/transaction/GetTransBankPage?` + queryParams, this.options)
  }

  getOrderAgentPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/orderagent/GetOrderAgentPage?` + queryParams, this.options)
  }

  getIntroPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/work/GetIntroPage?` + queryParams, this.options)
  }

  getHelperPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/work/GetHelperPage?` + queryParams, this.options)
  }

  getIntroInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/work/GetIntroInfo?` + queryParams, this.options)
  }

  getHelperInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/work/GetHelperInfo?` + queryParams, this.options)
  }

  getConvertPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/scheme/GetConvertPage?` + queryParams, this.options)
  }

  getSiptOpenSchemes(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenSchemes?` + queryParams, this.options)
  }

  getSiptOpenList(queryParams = null): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenList?` + queryParams, this.options)
  }

  getWorkflowInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/work/GetWorkflowInfo?` + queryParams, this.options)
  }

  getSiptDepoInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/depository/GetDepoInfo?` + queryParams, this.options)
  }

  setWorkApprove(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/work/SetWorkApprove`, params, this.options);
  }

  setSiptDepoInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/depository/SetDepoInfo`, params, this.options);
  }

  setWorkSubmit(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/work/SetWorkSubmit`, params, this.options);
  }

  setSiptOrderUpgate(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderUpgate`, params, this.options);
  }

  setCooperatorInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/cooperator/SetCooperatorInfo`, params, this.options);
  }

  setSiptOrderHold(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetHoldInfo`, params, this.options);
  }

  setCooperatorStatus(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/cooperator/setCooperatorStatus`, params, this.options);
  }

  setOwnerStatus(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/owner/SetOwnerStatus`, params, this.options);
  }

  setSiptOrderRelease(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/hold/SetOrderRelease`, params, this.options);
  }

  getOwnerPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/owner/GetOwnerPage?` + queryParams, this.options);
  }

  getSiptUserHolderPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetUserHolderPage?` + queryParams, this.options);
  }

  getSiptAccountPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/account/GetAccountPage?` + queryParams, this.options);
  }

  getCooperatorPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/cooperator/GetCooperatorPage?` + queryParams, this.options);
  }

  getSiptSchemeList(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/scheme/GetSchemeList`, this.options);
  }

  getSiptOrderPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetOrderPage?` + queryParams, this.options);
  }

  getOrderHistoryPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/final/GetOrderHistoryPage?` + queryParams, this.options);
  }

  getVoucherPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetVoucherPage?` + queryParams, this.options);
  }

  getSiptOrderInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetOrderInfo?` + queryParams, this.options);
  }

  getOrderFlow(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetOrderFlow?` + queryParams, this.options);
  }

  getSiptProductPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/product/GetProductPage?` + queryParams, this.options);
  }

  getOrderContPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetOrderContPage?` + queryParams, this.options);
  }

  getTransactionPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/transaction/GetTransactionPage?` + queryParams, this.options);
  }

  getSiptUserPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/account/GetUserPage?` + queryParams, this.options);
  }

  getCustObjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/work/GetObjectList?` + queryParams, this.options);
  }

  getTransAccounts(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/transaction/GetTransAccounts`, this.options);
  }

  getSiptOpenPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenPage?` + queryParams, this.options);
  }

  getSiptOpenSalePage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenSalePage?` + queryParams, this.options);
  }

  getSiptOwnList(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/owner/GetOwnerList`, this.options);
  }

  getSiptDepoList(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/depository/GetDepoList`, this.options);
  }

  getCooperatorList(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/cooperator/GetCooperatorList`, this.options);
  }

  getOwnerInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/owner/GetOwnerInfo?` + queryParams, this.options);
  }

  getSchemeInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/scheme/GetSchemeInfo?` + queryParams, this.options);
  }

  getOrderWithdrawalInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/withdrawal/GetOrderWithdrawalInfo?` + queryParams, this.options);
  }

  getOrderFinalInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/final/GetOrderFinalInfo?` + queryParams, this.options);
  }

  getLiquidationPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/liquidation/GetLiquidationPage?` + queryParams, this.options);
  }

  getSiptProdCouponPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/product/GetProdCouponPage?` + queryParams, this.options);
  }

  getSiptOrderHoldPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/hold/GetOrderHoldPage?` + queryParams, this.options);
  }

  getSiptProducInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/product/GetProducInfo?` + queryParams, this.options);
  }

  getSiptAgencyInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetAgencyInfo?` + queryParams, this.options);
  }

  getSiptProductList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/product/GetProductList?` + queryParams, this.options);
  }

  getSchemeList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/scheme/GetSchemeList?` + queryParams, this.options);
  }

  getSiptExchangeInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/exchange/GetExchangeInfo?` + queryParams, this.options);
  }

  getSiptOrderHoldInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetHoldInfo?` + queryParams, this.options);
  }

  getAccountInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/account/GetAccountInfo?` + queryParams, this.options);
  }

  getOrderHoldInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetOrderHoldInfo?` + queryParams, this.options);
  }

  getLiquidationInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/liquidation/GetLiquidationInfo?` + queryParams, this.options);
  }

  getConvertList(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/scheme/GetConvertList`, this.options);
  }

  getConvertDiscount(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/scheme/GetConvertDiscount?` + queryParams, this.options);
  }

  getSiptOpenInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenInfo?` + queryParams, this.options);
  }

  getContractFile(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetContractFile?` + queryParams, this.options);
  }

  getOrderRoomConverts(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/getOrderRoomConverts?` + queryParams, this.options);
  }

  getOpenDetail(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenDetail?` + queryParams, this.options);
  }

  getSiptOpenSale(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenSale?` + queryParams, this.options);
  }

  getSiptAgencyGeneralPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetAgencyGeneralPage?` + queryParams, this.options);
  }

  getSalerPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetSalerPage?` + queryParams, this.options);
  }

  getAgencyOrganizeMap(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetAgencyOrganizeMap`, this.options);
  }

  getSalerOrganizeMap(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetSalerOrganizeMap`, this.options);
  }

  getAgencyPolicyPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetAgencyPolicyPage?` + queryParams, this.options);
  }

  getAgencyPolicyInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetAgencyPolicyInfo?` + queryParams, this.options);
  }

  getAgencyPolicyShare(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetAgencyPolicyShare?` + queryParams, this.options);
  }

  getSchemeTenor(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/scheme/GetSchemeTenor?` + queryParams, this.options);
  }

  getOpenTenor(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenTenor?` + queryParams, this.options);
  }

  getOpenPolicyPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenPolicyPage?` + queryParams, this.options);
  }

  getReportList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/report/GetReportList?` + queryParams, this.options);
  }

  getWithdrawalInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetWithdrawalInfo?` + queryParams, this.options);
  }

  getSiptAgencyPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetAgencyPage?` + queryParams, this.options);
  }

  getOpenRoom(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenRoom?` + queryParams, this.options);
  }

  getTransactionInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/transaction/GetTransactionInfo?` + queryParams, this.options);
  }

  getConvertInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/scheme/GetConvertInfo?` + queryParams, this.options);
  }

  getSalerInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetSalerInfo?` + queryParams, this.options);
  }

  getOrderContInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetOrderContInfo?` + queryParams, this.options);
  }

  getOrderContDelive(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetOrderContDelive?` + queryParams, this.options);
  }

  getOpenVoucher(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenVoucher?` + queryParams, this.options);
  }

  getOrderIntMethods(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/liquidation/GetOrderIntMethods?` + queryParams, this.options);
  }

  getOpenTenors(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/liquidation/GetOpenTenors?` + queryParams, this.options);
  }

  getOpenSchemes(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenSchemes?` + queryParams, this.options);
  }

  setLiquidationChange(queryParams): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/liquidation/setLiquidationChange`, queryParams, this.options);
  }

  setOrderReferralChange(queryParams): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderReferralChange`, queryParams, this.options);
  }

  getOpenScheme(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenScheme?` + queryParams, this.options);
  }

  getSchemeTenorList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/scheme/GetSchemeTenorList?` + queryParams, this.options);
  }

  getOpenVoucherList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenVoucherList?` + queryParams, this.options);
  }

  delOwnerInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/owner/DelOwnerInfo?` + queryParams, this.options)
  }

  delConvertDiscount(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/scheme/DelConvertDiscount?` + queryParams, this.options)
  }

  delSiptProduct(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/product/DelProduct?` + queryParams, this.options)
  }

  delSchemeTenor(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/scheme/DelSchemeTenor?` + queryParams, this.options)
  }

  delTransaction(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/transaction/DelTransaction?` + queryParams, this.options)
  }

  delSiptDepoInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/depository/DelDepoInfo?` + queryParams, this.options)
  }

  delOpenTenor(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/open/DelOpenTenor?` + queryParams, this.options)
  }

  delOpenVoucher(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/open/DelOpenVoucher?` + queryParams, this.options)
  }

  setAgentClose(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/agency/SetAgentClose?` + queryParams, this.options)
  }

  setOrderInterestPlan(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/interest/SetOrderInterestPlan`, params, this.options)
  }

  setVoucherClean(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetVoucherClean`, params, this.options)
  }

  setVoucherPlan(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetVoucherPlan`, params, this.options)
  }

  setVoucherReceived(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetVoucherReceived`, params, this.options)
  }

  setOpenPolicy(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenPolicy`, params, this.options)
  }

  setOrderFinalPlan(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/final/SetOrderFinalPlan`, params, this.options)
  }

  setOpenScheme(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenScheme`, params, this.options)
  }

  setOrderWithdrawalDraft(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/withdrawal/SetOrderWithdrawalDraft`, params, this.options)
  }

  setLiquidationDraft(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/liquidation/SetLiquidationDraft`, params, this.options)
  }

  setLiquidationSet(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/liquidation/SetLiquidationSet`, params, this.options)
  }

  setOrderWithdrawalSet(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/withdrawal/SetOrderWithdrawalSet`, params, this.options)
  }

  setPayOnHalfOfFinal(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/final/SetPayOnHalfOfFinal`, params, this.options)
  }

  setPayOnHalfOfInterest(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/interest/SetPayOnHalfOfInterest`, params, this.options)
  }

  setPayOnHalfOfVoucher(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetPayOnHalfOfVoucher`, params, this.options)
  }

  setPayApproveOfVoucher(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetPayApproveOfVoucher`, params, this.options)
  }

  setTransactionCancel(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/transaction/SetTransactionCancel?` + queryParams, this.options)
  }

  setSalerInfo(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/agency/SetSalerInfo`, queryParams, this.options)
  }

  setOpenVoucher(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenVoucher`, queryParams, this.options)
  }

  setOpenRoomAdds(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenRoomAdds`, queryParams, this.options)
  }

  setOrderHold(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderHold`, queryParams, this.options)
  }

  setOrderRelease(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderRelease`, queryParams, this.options)
  }

  setOrderNotify(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderNotify`, queryParams, this.options)
  }

  setTransactionNotify(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/transaction/SetTransactionNotify`, queryParams, this.options)
  }

  setOrderLockNotify(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderLockNotify`, queryParams, this.options)
  }

  setOpenTenor(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenTenor`, queryParams, this.options)
  }

  setAgentApprove(queryParams): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/agency/SetAgentApprove`, queryParams, this.options)
  }

  setAgencyPolicyShare(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/agency/SetAgencyPolicyShare`, queryParams, this.options)
  }

  setSiptProductCancel(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/product/SetProductCancel?` + queryParams, this.options)
  }

  setSiptOpenCancel(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenStatus`, params, this.options)
  }

  setAgencyPolicyInfo(queryParams): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/agency/SetAgencyPolicyInfo`, queryParams, this.options)
  }

  setSiptOrderPaymentRollback(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderPaymentRollback`, params, this.options)
  }

  setSchemeTenor(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/scheme/SetSchemeTenor`, params, this.options)
  }

  setIntroInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/work/SetIntroInfo`, params, this.options)
  }

  setHelperInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/work/SetHelperInfo`, params, this.options)
  }

  setWithdrawalInfo(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetWithdrawalInfo`, params, this.options)
  }

  setOrderWithdrawal(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderWithdrawal`, params, this.options)
  }

  setAgencyOrganize(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/agency/SetAgencyOrganize`, params, this.options)
  }

  setConvertDiscount(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/scheme/SetConvertDiscount`, params, this.options)
  }

  setOrderMetaUpload(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderMetaUpload`, params, this.options)
  }

  setSiptOpenSale(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenSale`, params, this.options)
  }

  setOpenRoom(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenRoom`, params, this.options)
  }

  setExchangeApprove(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/exchange/SetExchangeApprove`, params, this.options)
  }

  setOpenRoomStatus(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenRoomStatus`, params, this.options)
  }

  setExchangeCancel(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/exchange/SetExchangeCancel`, params, this.options)
  }

  setConvertInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/scheme/SetConvertInfo`, params, this.options)
  }

  setSiptExchange(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/exchange/setExchange`, params, this.options)
  }

  setOrderRoomConvert(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderRoomConvert`, params, this.options)
  }

  setOrderRoomLock(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderRoomLock`, params, this.options)
  }

  delSiptOrder(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/order/DelOrder?` + queryParams, this.options)
  }

  delAgencyOrganize(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/agency/DelAgencyOrganize?` + queryParams, this.options)
  }

  delOpenPolicy(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/open/DelOpenPolicy?` + queryParams, this.options)
  }

  delOpenRoom(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/open/DelOpenRoom?` + queryParams, this.options)
  }

  delSiptExchange(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/exchange/Del=Exchange?` + queryParams, this.options)
  }

  delSiptOrderHold(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/order/DelHoldInfo?` + queryParams, this.options)
  }

  delSiptOrderMeta(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/order/DelOrderMeta?` + queryParams, this.options)
  }

  delOrderMetaUpload(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/order/DelOrderMetaUpload?` + queryParams, this.options)
  }

  delSiptOpenInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/open/DelOpenInfo?` + queryParams, this.options)
  }

  deleteSchemeInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/scheme/DelSchemeInfo?` + queryParams, this.options)
  }

  delWithdrawal(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/withdrawal/DelWithdrawal?` + queryParams, this.options)
  }

  delLiquidation(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/liquidation/DelLiquidation?` + queryParams, this.options)
  }

  delIntroInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/work/DelIntroInfo?` + queryParams, this.options)
  }

  delConvertInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/scheme/DelConvertInfo?` + queryParams, this.options)
  }

  delSiptAgencyInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/agency/DelAgencyInfo?` + queryParams, this.options)
  }

  delAgencyPolicyInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/agency/DelAgencyPolicyInfo?` + queryParams, this.options)
  }

  rejectAgencyPolicyInfo(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/agency/RejectAgencyPolicyInfo`, params, this.options)
  }

  delAgencyPolicyConvert(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/agency/DelAgencyPolicyConvert?` + queryParams, this.options)
  }

  delAgencyPolicyShare(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/agency/DelAgencyPolicyShare?` + queryParams, this.options)
  }

  delCooperatorInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/cooperator/DelCooperatorInfo?` + queryParams, this.options)
  }

  setOwnerInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/owner/SetOwnerInfo`, params, this.options)
  }

  setInvParameter(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/work/SetInvParameter`, params, this.options)
  }

  setSiptDepoStatus(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/depository/SetDepoStatus`, params, this.options)
  }

  setSiptOrderApproveReg(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderApproveReg`, params, this.options)
  }

  setSiptOrderPaymentApprove(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderPaymentApprove`, params, this.options)
  }

  setSiptOpenInfoApprove(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenInfoApprove`, params, this.options)
  }

  setOrderContDelive(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderContDelive`, params, this.options)
  }

  setOrderContReceive(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderContReceive`, params, this.options)
  }


  setSiptContract(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetContract`, params, this.options)
  }

  setSiptOpenOff(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenOff`, params, this.options)
  }

  setSiptOpenStatus(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenStatus`, params, this.options)
  }

  setSiptProductInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/product/SetProductInfo`, params, this.options)
  }

  setSiptUser(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/open/SetUser`, params, this.options)
  }

  setAccountInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/account/SetAccountInfo`, params, this.options)
  }

  setSiptOrderBook(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderBook`, params, this.options)
  }

  setSchemeInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/scheme/SetSchemeInfo`, params, this.options)
  }

  setOrderWithdrawalInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/withdrawal/SetOrderWithdrawalInfo`, params, this.options)
  }

  setSiptOpenInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/open/SetOpenInfo`, params, this.options)
  }

  setOrderPayment(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/transaction/SetOrderPayment`, params, this.options)
  }

  setOrderUpgate(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderUpgate`, params, this.options)
  }

  setAgencyPolicyConvert(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/agency/SetAgencyPolicyConvert`, params, this.options)
  }

  setContract(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/setContract`, params, this.options)
  }

  setTransactionInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/transaction/SetTransactionInfo`, params, this.options)
  }

  setSiptOrderMeta(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderMeta`, params, this.options)
  }

  setTransactionApprove(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/transaction/SetTransactionApprove`, params, this.options)
  }

  setOrderAgentCommit(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/orderagent/SetOrderAgentCommit`, params, this.options)
  }

  setNoPayApprovedOfFinal(queryParams): Observable<any> {
    return this.http.put(`${apiBondBaseUrl}/api/v2/final/SetNoPayApprovedOfFinal`, queryParams, this.options);
  }

  setPayOnHalfOfAgent(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/orderagent/SetPayOnHalfOfAgent`, params, this.options)
  }

  setNoPayApprovedOfInterest(queryParams): Observable<any> {
    return this.http.put(`${apiBondBaseUrl}/api/v2/interest/SetNoPayApprovedOfInterest`, queryParams, this.options);
  }

  delOrderFinalPlan(queryParams): Observable<any> {
    return this.http.delete(`${apiBondBaseUrl}/api/v2/final/DelOrderFinalPlan?` +queryParams, this.options);
  }

  setPayOnHalfApprove(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/transaction/SetPayOnHalfApprove`, params, this.options)
  }

  setPayOnRollOfFinal(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/final/SetPayOnRollOfFinal`, params, this.options)
  }

  setExchange(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/exchange/SetExchange`, params, this.options)
  }

  setSiptAgencyInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/agency/SetAgencyInfo`, params, this.options)
  }

  setLiquidationInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/liquidation/SetLiquidationInfo`, params, this.options)
  }

  getAgencyGeneralList(params): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetAgencyGeneralList?` + params, this.options);
  }

  getAgencyPolicyList(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetAgencyPolicyList`, this.options);
  }

  getAgencyPolicyConvert(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/agency/GetAgencyPolicyConvert?` + queryParams, this.options);
  }

  getAppNotifyPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v1/notify/GetAppNotifyPage?` + queryParams, this.options);
  }

  getNotifyToPushs(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v1/notify/GetNotifyToPushs?` + queryParams, this.options);
  }

  getAppNotifyInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v1/notify/GetAppNotifyInfo?` + queryParams, this.options);
  }

  getNotifyPushStatus(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v1/notify/GetNotifyPushStatus`, this.options);
  }

  getNotifyCommentList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v1/notify/GetNotifyCommentList?` + queryParams, this.options);
  }

  getNotifyCommentChilds(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v1/notify/GetNotifyCommentChilds?` + queryParams, this.options);
  }

  setAppNotifyInfo(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v1/notify/SetAppNotifyInfo`, params, this.options);
  }

  setNotifyToPushRun(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v1/notify/SetNotifyToPushRun`, params, this.options);
  }

  setNotifyCreatePush(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v1/notify/SetNotifyCreatePush`, params, this.options);
  }

  setOrderVoucher(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderVoucher`, params, this.options);
  }

  setNotifyCommentAuth(params): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v1/notify/SetNotifyCommentAuth`, params, this.options);
  }

  setNotifyComment(params): Observable<any> {
    return this.http.post<any>(`${apiBondBaseUrl}/api/v1/notify/SetNotifyComment`, params, this.options);
  }

  getProductList(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/product/GetProductList`, this.options);
  }

  getAccountList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/account/getAccountList?` + queryParams, this.options);
  }

  getAccountListCore(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/getAccountList?` + queryParams, this.options);
  }

  getOrderVoucherPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetOrderVoucherPage?` + queryParams, this.options);
  }

  getOrderVoucher(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/order/GetOrderVoucher?` + queryParams, this.options);
  }

  getExchangeInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/exchange/GetExchangeInfo?` + queryParams, this.options);
  }

  getOpenPolicy(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/open/GetOpenPolicy?` + queryParams, this.options);
  }

  getProductOrderPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/product/GetProductOrderPage?` + queryParams, this.options);
  }

  delNotifyPush(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v1/notify/DelNotifyPush?` + queryParams, this.options);
  }

  delExchange(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/exchange/DelExchange?` + queryParams, this.options);
  }

  getDocumentUrl<T>(filter, offset, pagesize) {
    return this.http.get<T>(apiBase + '/GetDocumentUrl?ProjectCd=&filter=' + filter + '&offSet=' + offset + '&pageSize=' + pagesize, this.options).toPromise();
  }

  setDocumentUrl<T>(documentUrl) {
    const doc = {
      projectCd: '',
      documentTitle: '',
      documentUrl
    };
    return this.http.post<T>(apiBase + '/SetDocumentUrl', doc, this.options).toPromise();
  }

  delAppNotifyInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v1/notify/DelAppNotifyInfo?` + queryParams, this.options);
  }

  delOrderVoucher(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiBondBaseUrl}/api/v2/order/DelOrderVoucher?` + queryParams, this.options);
  }

  delNotifyPushs(queryParams): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v1/notify/DelNotifyPushs`, queryParams, this.options);
  }

  getSiptFileByType<T>(params) {
    return this.http.get<T>(`${apiBondBaseUrl}/api/v2/order/GetFileByType?` + params, this.options).toPromise();
  }

  getIdentityCardInfomation(image: File) {
    const options = {
      headers: new HttpHeaders({
        'key': 'WZWfWCN2VPDxbYsV6sRfR0N1fV8x030h'
      })
    };
    const formdata = new FormData();
    formdata.append('image', image, 'TanTano');
    formdata.append('request_id', '14071996');
    return this.http.post('https://api.cloudekyc.com/v3.2/ocr/recognition', formdata, options);
  }

  getBusinessLicense(image: File) {
    const options = {
      headers: new HttpHeaders({
        // 'key': 'WZWfWCN2VPDxbYsV6sRfR0N1fV8x030h'})
        'key': 'Z9Gsi9B0sOK1QVZLayoAMZ2wJPi89Es9'
      })
    };
    const formdata = new FormData();
    formdata.append('image', image, 'TanTano');
    formdata.append('request_id', '14071996');
    return this.http.post('https://api.cloudekyc.com/bl/recognition', formdata, options);
  }

  // api v1

  getInvProjectList(): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v1/investweb/GetInvProjectList`, this.options)
  }

  getProjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/product/GetProjectList?` + queryParams, this.options)
  }
  getTransBankAccountPage(queryParams): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/transaction/GetTransBankAccountPage`, queryParams, this.options);
  }

  // Đạt lệnh

  getOrderBookPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiBondBaseUrl}/api/v2/book/GetOrderBookPage?${queryParams}`, this.options);
  }

  setOrderBookStatus(params: any): Observable<any> {
    return this.http.post(`${apiBondBaseUrl}/api/v2/book/SetOrderBookStatus`, params, this.options);
  }

  setOrderBookApprove(params: any): Observable<any> {
    return this.http.post(`${apiBondBaseUrl}/api/v2/book/SetOrderBookApprove`, params, this.options);
  }

  delOrderBook(queryParams: any): Observable<any> {
    return this.http.delete(`${apiBondBaseUrl}/api/v2/book/DelOrderBook?${queryParams}`, this.options);
  }

  getOrderBookInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiBondBaseUrl}/api/v2/book/GetOrderBookInfo?${queryParams}`, this.options);
  }

  getObjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiBondBaseUrl}/api/v2/work/GetObjectList?` + queryParams, this.options);
  }

  setOrderBookDraft(queryParams: any): Observable<any> {
    return this.http.post(`${apiBondBaseUrl}/api/v2/book/SetOrderBookDraft`, queryParams, this.options);
  }

  setOrderBookInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiBondBaseUrl}/api/v2/book/SetOrderBookInfo`, queryParams, this.options);
  }

  getOpenList(queryParams: any): Observable<any> {
    return this.http.get(`${apiBondBaseUrl}/api/v2/open/GetOpenList?` + queryParams, this.options);
  }

  setOrderBenAccountChange(queryParams): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderBenAccountChange`, queryParams, this.options);
  }

  setOrderUpOnline(queryParams): Observable<any> {
    return this.http.put<any>(`${apiBondBaseUrl}/api/v2/order/SetOrderUpOnline`, queryParams, this.options);
  }
  
  setResetPassword(password): Observable<any> {
    return this.http
    .put<any>(`${apiCoreBaseUrl}/api/v1/coresystem/SetResetPassword`, password , this.options);
  }

  GetPriorityCustomerList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBase}/api/v1/priority-customer/GetPriorityCustomerManagePage?` + queryParams, this.options);
  }

  GetPriorityCustomerDetail(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBase}/api/v1/priority-customer/GetPriorityCustomerDetail?` + queryParams, this.options);
  }

  getObjectListInUser(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBase}/api/v1/user/GetObjectList?` + queryParams, this.options);
  }

  exportExcelKHUT(queryParams): Observable<any> {
    // @ts-ignore
    return this.http.get<any>(`${apiKSFBase}/api/v1/priority-customer/GetReportPriorityCustomerManagePage?` + queryParams, {...this.options, responseType: 'blob'});
  }

}
