import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

/**sunshine component */
import { SunshineComponent } from './sunshine/sunshine.component';
import { HomeComponent } from './home/home.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { path: 'auth-callback', component: AuthCallbackComponent },
  /**Sunshine route */
  {
    path: '', component: SunshineComponent,
    data: { title: '' }, children: [
      { path: 'home', component: HomeComponent }
      , {
        path: 'page-open',
        loadChildren: () => import('./page/page-open/page-open.module').then(m => m.PageOpenModule)
      }, {
        path: 'page-scheme',
        loadChildren: () => import('./page/page-scheme/page-scheme.module').then(m => m.PageSchemeModule)
      }, {
        path: 'page-product',
        loadChildren: () => import('./page/page-product/page-product.module').then(m => m.PageProductModule)
      }, {
        path: 'page-order',
        loadChildren: () => import('./page/page-order/page-order.module').then(m => m.PageOrderModule)
      }, {
        path: 'page-order-history',
        loadChildren: () => import('./page/order-history/order-history.module').then(m => m.OrderHistoryModule)
      }, {
        path: 'page-liquidation',
        loadChildren: () => import('./page/page-liquidation/page-liquidation.module').then(m => m.PageLiquidationModule)
      }, {
        path: 'page-contract',
        loadChildren: () => import('./page/page-contract/page-contract.module').then(m => m.PageContractModule)
      }, {
        path: 'page-convert-bds',
        loadChildren: () => import('./page/page-convert-bds/page-convert-bds.module').then(m => m.PageConvertBdsModule)
      }, {
        path: 'page-agency-policy',
        loadChildren: () => import('./page/page-agency-policy/page-agency-policy.module').then(m => m.PageAgencyPolicyModule)
      }, {
        path: 'page-order-hq',
        loadChildren: () => import('./page/page-order-hq/page-order-hq.module').then(m => m.PageOrderHqModule)
      }, {
        path: 'rut-von',
        loadChildren: () => import('./page/page-withdrawal/page-withdrawal.module').then(m => m.PageWithdrawalModule)
      }, {
        path: 'tra-lai',
        loadChildren: () => import('./page/page-interest/page-interest.module').then(m => m.PageInterestModule)
      }, {
        path: 'sun',
        loadChildren: () => import('./sipt-setting/sipt-setting.module').then(m => m.SiptSettingModule)
      }, {
        path: 'page-order-final',
        loadChildren: () => import('./page/page-order-final/page-order-final.module').then(m => m.PageOrderFinalModule)
      }, {
        path: 'gioi-thieu-du-an',
        loadChildren: () => import('./page/page-project-info/page-project-info.module').then(m => m.PageProjectInfoModule)
      }, {
        path: 'huong-dan',
        loadChildren: () => import('./page/page-helper/page-helper.module').then(m => m.PageHelperModule)
      },
      {
        path: 'page-user',
        loadChildren: () => import('./page/page-user/page-user.module').then(m => m.PageUserModule)
      },
      {
        path: 'page-customer',
        loadChildren: () => import('./page/page-customer/page-customer.module').then(m => m.PageCustomerModule)
      },
      {
        path: 'page-cooperator',
        loadChildren: () => import('./page/page-cooperator/page-cooperator.module').then(m => m.PageCooperatorModule)
      },
      {
        path: 'page-own',
        loadChildren: () => import('./page/page-own/page-own.module').then(m => m.PageOwnModule)
      },
      {
        path: 'page-exchange',
        loadChildren: () => import('./page/page-exchange/page-exchange.module').then(m => m.PageExchangeModule)
      },
      {
        path: 'page-order-agent',
        loadChildren: () => import('./page/page-order-agent/page-order-agent.module').then(m => m.PageOrderAgentModule)
      },
      {
        path: 'page-transbank',
        loadChildren: () => import('./page/page-transbank/page-transbank.module').then(m => m.PageTransbankModule)
      },
      {
        path: 'page-invparam',
        loadChildren: () => import('./page/page-invparam/page-invparam.module').then(m => m.PageInvparamModule)
      },
      {
        path: 'page-trans-bank-payon',
        loadChildren: () => import('./page/page-trans-bank-payon/page-trans-bank-payon.module').then(m => m.PageTransBankPayonModule)
      },
      {
        path: 'notification',
        loadChildren: () => import('./page/page-notify/page-notify.module').then(m => m.PageNotifyModule)
      },
      {
        path: 'page-order-cont',
        loadChildren: () => import('./page/page-order-cont/page-order-cont.module').then(m => m.PageOrderContModule)
      },
      {
        path: 'chi-tra-uu-dai',
        loadChildren: () => import('./page/chi-tra-uu-dai/chi-tra-uu-dai.module').then(m => m.ChiTraUuDaiModule)
      },
      {
        path: 'report',
        loadChildren: () => import('./page/report/report.module').then(m => m.PageReportModule)
      },
      {
        path: 'dat-lenh',
        loadChildren: () => import('./page/dat-lenh/dat-lenh.module').then(m => m.DatLenhModule)
      },
      {
        path: 'page-trans-bank-payon',
        loadChildren: () => import('./page/statement-account/statement-account.module').then(m => m.StatementAccountModule)
      },
      {
        path: 'quan-ly-khach-hang-vip',
        loadChildren: () => import('./page/quan-ly-khach-hang-vip/quan-ly-khach-hang-vip.module').then(m => m.QuanLyKhachHangVipModule)
      }
    ], canActivate: [AuthGuardService]

  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
