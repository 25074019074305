import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmationService, MessageService } from 'primeng/api';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    username = '';
    avatarUrl = '';
    isShowChangePassword: boolean = false;
    confimPassword = false;
    submitPass = false;
    modelPass = {
        userLogin: '',
        userPassword: '',
        userPassCf: ''
      }
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private apiService: ApiService,
        private messageService: MessageService
    ) { }

    signout() {
        this.authService.signout();
    }

    ngOnInit() {
        this.username = this.authService.getUserName();
        this.avatarUrl = this.authService.getUserImage();
    }

    isChangePassword() {
        this.isShowChangePassword = true;
        this.modelPass.userLogin = '';
        this.modelPass.userPassword = '';
        this.modelPass.userPassCf = '';
    }

    checkPasswordcf() {
        if (this.modelPass.userPassword === this.modelPass.userPassCf) {
            this.confimPassword = false;
        } else {
            this.confimPassword = true;
        }
    }
    
    saveChangePass() {
        let userLogin: any = this.authService.getClaims();
        this.submitPass = true;
        if ((this.modelPass.userPassword && !this.modelPass.userPassCf) || this.confimPassword) {
          return;
        }
        this.modelPass.userLogin = userLogin.preferred_username
        const params = { ... this.modelPass };
        delete params.userPassCf
        this.apiService.setResetPassword(params).subscribe(results => {
          if (results.status === 'success') {
            this.messageService.add({ severity: 'success', summary: 'Thông báo', detail: 'Sửa thông tin tài khoản thành công !' });
            this.isShowChangePassword = false;
          }
          if(results.status === 'error'){
            this.messageService.add({ severity: 'error', summary: results.message, detail: results.data });
          }
        })
    
      }
}
