import { Component, OnInit } from '@angular/core';
import { UserManager } from 'oidc-client';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseAuthService } from 'src/app/services/firebase-auth.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiCoreService } from 'src/app/services/api-core/api-core.service';
declare var $: any;
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    private manager: UserManager = new UserManager(environment.authenSettings);
    constructor(
        private apiService: ApiService,
        private apiCore: ApiCoreService,
        private http: HttpClient,
        private firebaseAuthService: FirebaseAuthService,
        private authService: AuthService,
    ) {
    }

   async ngOnInit() {
        if (localStorage.getItem('menuItems')) {
            $.getScript('./assets/app/js/core/app-menu.js');
            $.getScript('./assets/app/js/core/app.js');
            this.menuItems = JSON.parse(localStorage.getItem('menuItems'));
            this.menuItems = this.menuItems.filter(menuItem => menuItem);
        } else {
            $.getScript('./assets/app/js/core/app-menu.js');
            $.getScript('./assets/app/js/core/app.js');
            this.manager.getUser().then(user => {
                this.apiCore.getListMenuByUserId(user.profile.sub, '70E930B0-FFEA-43D3-B3A9-0E6B03F2B433').subscribe(results => {
                    this.menuItems = results.data.filter(menuItem => menuItem);
                    localStorage.setItem('menuItems', JSON.stringify(results.data));
                })
            });
        }
        if (!localStorage.getItem('tokenFb')) {
            const token = this.authService.getAccessTokenValue();
            if (!this.firebaseAuthService.authenticated) {
                localStorage.setItem('tokenFb', token);
                const customToken = await this.getCustomToken(token);
                if (customToken) {
                    this.firebaseAuthService.customLogin(customToken);
                }
            }
        }
        
    }

    getCustomToken(token: string): Promise<string> {
        const url = `${environment.cloudFunctionServer}/getCustomToken`;
        return this.http.post(url, {
          data: {
            access_token: token
          }
        }).pipe(
          map((response: any) => response.result)
        ).toPromise();
    
      }

      activeMenu(event) {
          if(event.path[2] && event.path[2].className.includes('open active')) {
            localStorage.setItem('menuId', JSON.stringify(event.path[2].id));
          }
      }

}
